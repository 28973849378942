/* cyrillic-ext */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;600&display=swap');

// Font Awesome
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'); 

// icons font 
@import "../../dist/assets/fonts/icofont/css/iconfont";

// Bootstarp font icons
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");