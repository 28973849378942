.table-hover>tbody>tr:hover{
    --bs-table-accent-bg: var(--color-100);
}
table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before, 
table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before{
    top: 50%;
    background-color:var(--primary-color);
    transform: translateY(-50%);
}
table.dataTable>tbody>tr.child ul.dtr-details{
    display: block;
}

#myProjectTable_length,#myProjectTable_filter,#myProjectTable_info,
#myDataTable_length,#myDataTable_filter,#myDataTable_info,
#patient-table_length,#patient-table_filter,#patient-table_info{
    @media only screen and (max-width:  $break-small - 1px) {
        display: none;
    }
}

#myProjectTable_paginate,#myDataTable_paginate,#patient-table_paginate{
    ul.pagination {
        @media only screen and (max-width:  $break-small - 1px) {
            justify-content: start!important;
        }
    }
}

#myCartTable_length,#myCartTable_filter,#myCartTable_info,#myCartTable_paginate{
    display: none;
}