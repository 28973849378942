//Fonts Family
$font-family: 		  'Open Sans', sans-serif;
$font-opensans: 	  'Open Sans', sans-serif;
$font-poppins: 	      'Poppins', sans-serif;
$font-montserrat: 	  'Montserrat', sans-serif;
$font-mukta: 	      'Mukta', sans-serif; 


// Bootstrap colors
$color-success: 		#4DCA88;
$color-danger: 			#FC5A69;
$color-warning: 		#FFBA33;
$color-info: 			#4FB8C9;

// Fonts
$font-size: 			14px;

$tradewind:             #6aab9c !default;
$monalisa:              #FF7B89 !default;
$indigos: 			    #484c7f !default;
$purples: 			    #484c7f !default;
$oranges:				#f8b500 !default;
$yellow: 				#FFBA33 !default;
$greens: 				#7EB93F !default;
$blues:					#7258db !default;
$blush: 				#ED5782 !default;
$cyans:					#00bdaa !default;
$reds:	 			    #FF1843 !default;
$info:		 			#4FB8C9 !default;
$white:		 			#ffffff !default;
$dark:		 			#191818 !default;

// scss-docs-start colors-map
$c_colors: (
"tradewind":  #6aab9c,
"monalisa":   #FF7B89,
"blue":       #7258db,
"blush":      #ED5782,
"indigo":     #484c7f,
"purple":     #484c7f,
"red":        #FF1843,
"orange":     #f8b500,
"yellow":     #FFBA33,
"green":      #7EB93F,
"cyan":       #00bdaa,
) !default;

$sidebar-width: 		250px;
$rightbar-width: 		280px;
$sidebar-icon:	 		50px;

// Breakpoints
$break-xxsmall: 		390px;
$break-xsmall: 			640px;
$break-small: 			768px;
$break-medium: 			992px;
$break-1024: 			1024px;
$break-large: 			1200px;
$break-1280: 			1280px;
$break-xlarge: 			1440px;